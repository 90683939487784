import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          hero: {
            title: 'Meet <em>Buddy Bear</em>, The AI Teddy That Talks',
            subtitle: 'Buddy is a bear that listens, thinks, and talks… You’ll be amazed by what they can do.',
            hearBuddyVoice: 'Hear Buddy Voice',
            playingBuddyVoice: 'Playing...',
          },
          getyourbuddy: {
            title: 'Bring Buddy Home Today!',
            subtitle: 'The adorable and intelligent bear ready to fill your days with joy.'
          },
          manual: {
            title: 'What makes Buddy special?',
            cardOne: {
              title: 'Buddy Listens and Understands',
              description: 'With advanced AI, Buddy can hear and respond to your voice in a smart and engaging way.',
            },
            cardTwo: {
              title: 'Buddy Learns and Remembers',
              description: 'Buddy remembers your conversations and preferences, building a bond that feels like true friendship.',
            },
            cardThree: {
              title: 'Every Buddy Is Unique',
              description: 'Your Buddy has a personality of its own. You can name your Buddy, introduce yourself, and start a one-of-a-kind friendship.',
            },
            downloadManual: 'View Buddy Manual',
          }
        },
      },
      th: {
        translation: {
          hero: {
            title: 'พบกับ<em>บัดดี้แบร์</em> หมีพูดได้พร้อมสมอง AI',
            subtitle: 'บัดดี้คือหมีที่ฟังได้ คิดเองได้ และพูดได้… คุณจะทึ่งเมื่อรู้ว่าเขาทำอะไรได้บ้าง',
            hearBuddyVoice: 'ลองฟังเสียงบัดดี้',
            playingBuddyVoice: 'กำลังพูด...',
          },
          getyourbuddy: {
            title: 'พาบัดดี้กลับบ้านได้ทาง LINE OA',
            subtitle: 'เพื่อนหมีที่น่ารักและแสนฉลาด พร้อมสร้างความสุขให้ทุกวันของคุณ'
          },
          manual: {
            title: 'ทำไมบัดดี้ถึงพิเศษ?',
            cardOne: {
              title: 'บัดดี้รับฟังและเข้าใจ',
              description: 'ด้วยสมอง AI ที่ล้ำสมัย บัดดี้สามารถฟังและตอบสนองต่อเสียงของคุณได้อย่างอัจฉริยะ',
            },
            cardTwo: {
              title: 'บัดดี้เรียนรู้และจดจำคุณ',
              description: 'บัดดี้จดจำบทสนทนาและความชอบของคุณ เพื่อสร้างความสัมพันธ์ที่ใกล้ชิดเหมือนเพื่อนแท้',
            },
            cardThree: {
              title: 'บัดดี้แต่ละตัวไม่เหมือนใคร',
              description: 'บัดดี้ของคุณมีบุคลิกเฉพาะตัว และคุณสามารถตั้งชื่อให้เขา พร้อมแนะนำตัวเองเพื่อเริ่มต้นมิตรภาพที่แสนพิเศษ',
            },
            downloadManual: 'ดูคู่มือการใช้งาน',
          }
        },
      },
    },
    lng: 'th',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;