// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { FaTiktok, FaInstagram, FaLine } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Business Information */}
        <div className="business-info">
          <h3>BuddyAndMe Co., Ltd.</h3>
          <p>Email: <a href="mailto:contact@buddyand.me">contact@buddyand.me</a> Telephone: <a href="tel:+66897645335">+6689 764 5335</a></p>
        </div>

        {/* Legal Links */}
        <div className="legal-links">
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          <a href="/disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>
        </div>

        {/* Social Media Links */}
        <div className="social-media-links">
          <a href="https://www.tiktok.com/@buddyandme.official" target="_blank" rel="noopener noreferrer" aria-label="Tiktok">
            <FaTiktok />
          </a>
          <a href="https://www.instagram.com/buddyandme.official" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://lin.ee/YzRBhuR" target="_blank" rel="noopener noreferrer" aria-label="Line">
            <FaLine />
          </a>
        </div>
      </div>

      {/* Footer Bottom Bar */}
      <div className="footer-bottom-bar">
        <p>© 2024 BuddyAndMe Co., Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
