import React, { useState } from 'react';
import './App.css';

import HeroSection from './HeroSection';
import ManualSection from './ManualSection';
import GetYourBuddySection from './GetYourBuddy';
import Footer from './Footer';

import './i18n';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('th'); // Default language is Thai

  // Function to change language
  const changeLanguage = () => {
    const newLang = language === 'th' ? 'en' : 'th';
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };

  return (
    <div className="app">
      {/* Floating Language Switch Button */}
      <div className="floating-lang-switch" onClick={changeLanguage}>
        {language === 'th' ? 'EN' : 'ไทย'}
      </div>
      
      <HeroSection />
      <GetYourBuddySection />
      <ManualSection />
      
      <Footer />
    </div>
  );
}

export default App;
