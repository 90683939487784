// src/components/ManualSection.js
import React from 'react';
import './ManualSection.css';

import { useTranslation } from 'react-i18next';
import { FaAssistiveListeningSystems, FaBrain, FaStar } from 'react-icons/fa';

const ManualSection = () => {
  const { t } = useTranslation();

  const downloadManual = () => {
    window.open('/manuals/buddy-manual.pdf', '_blank');
  };

  return (
    <div className="manual-container">
      <img
        src="/images/macaron.png"
        alt="Macaron"
        className="manual-top-left-graphic"
        loading="lazy"
      />

      {/* Main Content of Manual Section */}
      <div className="manual-content">
        <div className="manual-text">
          <h2>{t('manual.title')}</h2>
        </div>

        {/* Features Showcase */}
        <div className="features-showcase">
          <div className="feature-card">
            <FaAssistiveListeningSystems className="feature-icon" />
            <h4>{t('manual.cardOne.title')}</h4>
            <p>{t('manual.cardOne.description')}</p>
          </div>
          <div className="feature-card">
            <FaBrain className="feature-icon" />
            <h4>{t('manual.cardTwo.title')}</h4>
            <p>{t('manual.cardTwo.description')}</p>
          </div>
          <div className="feature-card">
            <FaStar className="feature-icon" />
            <h4>{t('manual.cardThree.title')}</h4>
            <p>{t('manual.cardThree.description')}</p>
          </div>
        </div>

        {/* Download Manual Button */}
        <div className="download-manual">
          <button onClick={downloadManual} className="manual-download-button">
            {t('manual.downloadManual')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualSection;
