import React, { useEffect } from 'react';
import './GetYourBuddy.css';

import { useTranslation } from 'react-i18next';

const GetYourBuddySection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const buttons = document.querySelectorAll('.links-container button');
    buttons.forEach((button, index) => {
      button.style.setProperty('--button-index', index);
    });
  }, []);

  return (
    <div className="getyourbuddy-container">
      {/* Shooting Stars */}
      <div className="shooting-star"></div>
      <div className="shooting-star"></div>
      <div className="shooting-star"></div>

        
      <div className="big-shooting-star">
        <div className="star-head"></div>
      </div>

      <div className="getyourbuddy-content">
        <h1 className="getyourbuddy-title">{t('getyourbuddy.title')}</h1>
        <p className="getyourbuddy-description">
          {t('getyourbuddy.subtitle')}
        </p>
        <img
          src="/images/buddy-and-box.png"
          alt="Buddy-and-box"
          className="getyourbuddy-graphic"
          loading="lazy"
        />
        <div className="links-container">
          <button className="lineoa-button" onClick={() => window.open('https://lin.ee/YzRBhuR', '_blank')}>
            <img src="/icons/line-icon.png" alt="LINE OA" className="button-icon" />
            LINE OA
          </button>
          <button className="tiktok-button" onClick={() => window.open('https://www.tiktok.com/@buddyandme.official', '_blank')}>
            <img src="/icons/tiktok-icon.png" alt="TikTok" className="button-icon" />
            TikTok
          </button>
          <button className="instagram-button" onClick={() => window.open('https://www.instagram.com/buddyandme.official', '_blank')}>
            <img src="/icons/instagram-icon.png" alt="Instagram" className="button-icon" />
            Instagram
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetYourBuddySection;
