// src/components/HeroSection.js
import React, { useRef, useState, useEffect } from 'react';
import './HeroSection.css';
import { Howl, Howler } from 'howler';
import { FaPlay } from 'react-icons/fa';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  const buddyVoiceRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const buttonRef = useRef(null);
  const topLeftGraphicRef = useRef(null);
  const bottomLeftGraphicRef = useRef(null);
  const rightGraphicRef = useRef(null);

  // Initialize Howler for audio playback
  useEffect(() => {
    buddyVoiceRef.current = new Howl({
      src: ['/audio/buddy-demo-sentence.mp3'],
      volume: 0.5,
      onplay: () => setIsPlaying(true),
      onend: () => setIsPlaying(false),
      onstop: () => setIsPlaying(false),
      onpause: () => setIsPlaying(false),
      onloaderror: (id, error) => {
        console.error('Load Error:', error);
      },
      onplayerror: (id, error) => {
        console.error('Play Error:', error);
      },
    });

    // Cleanup Howl instance on component unmount
    return () => {
      if (buddyVoiceRef.current) {
        buddyVoiceRef.current.unload();
      }
    };
  }, []);

  // Implement Parallax Effect with Rotation
  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = window.pageYOffset;

      if (topLeftGraphicRef.current && rightGraphicRef.current && bottomLeftGraphicRef.current) {
        // Adjust the multiplier to control the parallax speed
        const translateY = scrollTop * -0.8;
        const rotateDeg = scrollTop * 0.2; // Adjust multiplier for rotation speed

        // Update CSS variables for dynamic transformations
        topLeftGraphicRef.current.style.setProperty('--dynamic-translate-left', `${translateY}px`);
        topLeftGraphicRef.current.style.setProperty('--dynamic-rotation-left', `${rotateDeg}deg`);

        bottomLeftGraphicRef.current.style.setProperty('--dynamic-translate-left', `${translateY}px`);
        bottomLeftGraphicRef.current.style.setProperty('--dynamic-rotation-left', `${rotateDeg}deg`);

        rightGraphicRef.current.style.setProperty('--dynamic-translate-right', `${translateY}px`);
        rightGraphicRef.current.style.setProperty('--dynamic-rotation-right', `${rotateDeg}deg`);
      }
    }, 8); // fps

    window.addEventListener('scroll', handleScroll, { passive: true });

    // Call handleScroll once to set initial transform
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const playVoice = async () => {
    try {
      // Resume the AudioContext if it's suspended
      if (Howler.ctx.state === 'suspended') {
        await Howler.ctx.resume();
        console.log('AudioContext resumed');
      }

      if (buddyVoiceRef.current) {
        console.log('Playing Buddy Voice...');
        buddyVoiceRef.current.play();
      } else {
        console.error('Buddy Voice audio not loaded.');
      }
    } catch (error) {
      console.error('Error resuming AudioContext:', error);
    }
  };

  return (
    <div className="hero-container">
      {/* Left Graphic */}
      <img
        src="/images/macaron.png" // Ensure this path is correct
        alt="Macaron"
        className="hero-graphic top-left-graphic"
        ref={topLeftGraphicRef}
        loading="lazy"
      />

      <img
        src="/images/donut.png" // Ensure this path is correct
        alt="Donut"
        className="hero-graphic bottom-left-graphic"
        ref={bottomLeftGraphicRef}
        loading="lazy"
      />

      {/* Hero Content */}
      <div className="hero-content">
        <div className="hero-image">
          <img src="/images/buddy.png" className="hero-graphic buddy-graphic" alt="Buddy Bear" loading="lazy" />
        </div>
        <div className="hero-text">
          <h2 dangerouslySetInnerHTML={{ __html: t('hero.title') }}></h2>
          <h3>{t('hero.subtitle')}</h3>
          <div className="hero-button">
            <button onClick={playVoice} disabled={isPlaying} ref={buttonRef} aria-label="Play Buddy Bear Voice">
              <FaPlay className="play-icon" />
              {isPlaying ? t('hero.playingBuddyVoice') : t('hero.hearBuddyVoice')}
            </button>
            <audio
              ref={buddyVoiceRef}
              src="/audio/buddy-demo-sentence.mp3"
              onPlay={() => setIsPlaying(true)}
              onEnded={() => setIsPlaying(false)}
              onPause={() => setIsPlaying(false)}
            />
          </div>
        </div>
      </div>

      {/* Right Graphic */}
      <img
        src="/images/cupcake.png" // Ensure this path is correct
        alt="Cupcake"
        className="hero-graphic right-graphic"
        ref={rightGraphicRef}
        loading="lazy"
      />
    </div>
  );
};

export default HeroSection;
